<template>
    <el-table v-if="tableData.length > 0" id="tableId" :data="tableData" class="w-full">
        <el-table-column prop="status" width="25">
            <template slot-scope="scope">
                <el-tooltip
                    v-if="workingStatus(scope.row)"
                    :enterable="false"
                    effect="dark"
                    :content="$t('clients.working')"
                    placement="right"
                >
                    <span class="w-3 h-3 bg-green-500 flex rounded-full" />
                </el-tooltip>
                <el-tooltip
                    v-if="shouldFinishStatus(scope.row)"
                    :enterable="false"
                    effect="dark"
                    :content="$t('clients.should_finish_already_20_min')"
                    placement="right"
                >
                    <span class="w-3 h-3 bg-orange-400 flex rounded-full" />
                </el-tooltip>
                <el-tooltip
                    v-if="scope.row.status === 2"
                    :enterable="false"
                    effect="dark"
                    :content="$t('clients.didnt_checked_out')"
                    placement="right"
                >
                    <span class="w-3 h-3 bg-red-500 flex rounded-full" />
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column :label="$t('clients.address')" prop="address" :show-overflow-tooltip="true">
            <template slot-scope="scope">
                <router-link :to="`/clients/${clientUuid}/addresses/${scope.row.address_uuid}`" class="hover:text-red-500 hover:font-semibold">
                    {{ scope.row.address }}
                </router-link>
            </template>
        </el-table-column>
        <el-table-column :label="$t('clients.employee')" prop="employee" :show-overflow-tooltip="true" sortable width="110">
            <template slot-scope="scope">
                <router-link :to="`/employees/${scope.row.employee_uuid}/information`" class="hover:text-red-500 hover:font-semibold">
                    {{ scope.row.employee }}
                </router-link>
            </template>
        </el-table-column>
        <el-table-column :label="$t('clients.duration')" prop="duration" width="100">
            <template slot-scope="scope">
                {{ normalizeTime(scope.row.duration) }}
            </template>
        </el-table-column>
        <el-table-column :label="$t('clients.planned')" prop="planned" width="100">
            <template slot-scope="scope">
                {{ normalizeTime(scope.row.planned) }}
            </template>
        </el-table-column>
        <el-table-column v-if="userIsCleaningCompany()" class="printableHidden" :label="$t('clients.rate')" width="170">
            <template slot-scope="scope">
                <div class="flex items-center">
                    <el-rate :value="scope.row.rate ? scope.row.rate.rate : 0" disabled />
                    <el-popover
                        v-if="scope.row.rate && scope.row.rate.comment"
                        :enterable="false"
                        :openDelay="500"
                        placement="top-end"
                        :title="$t('clients.comment')"
                        width="300"
                        trigger="hover"
                        :content="scope.row.rate.comment"
                    >
                        <span slot="reference" class="flex h-5 w-5 justify-center items-center rounded-full border border-gray-600">
                            <fa-icon :icon="['fas', 'info']" />
                        </span>
                    </el-popover>
                </div>
            </template>
        </el-table-column>
        <el-table-column :label="$t('clients.date')" prop="date" width="130" />
        <el-table-column width="75">
            <template slot-scope="scope">
                <el-button-group>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.edit')" placement="left">
                        <el-button type="primary" size="mini" :disabled="!userCan('update service history')" @click="handleEdit(scope.row.id)">
                            <fa-icon :icon="['far', 'edit']" />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.delete')" placement="top">
                        <el-button type="danger" size="mini" :disabled="!userCan('delete service history')" @click="handleDelete(scope.row.id)">
                            <fa-icon :icon="['fas', 'trash']" />
                        </el-button>
                    </el-tooltip>
                </el-button-group>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>

export default {
    props: {
        tableData: {
            type:    Array,
            default: () => [],
        },
    },

    data() {
        return {
            clientUuid: this.$route.params.uuid,
        };
    },

    methods: {
        shouldFinishStatus(row) {
            const shouldFinishAt = this.$dayjs(row.date, 'HH:mm DD.MM.YYYY').add(row.planned + 20, 'minute');
            if (row.status === 0 && this.$dayjs() > shouldFinishAt) {
                return true;
            }
            return false;
        },

        workingStatus(row) {
            const shouldFinishAt = this.$dayjs(row.date, 'HH:mm DD.MM.YYYY').add(row.planned + 20, 'minute');
            if (row.status === 0 && this.$dayjs() < shouldFinishAt) {
                return true;
            }
            return false;
        },

        handleEdit(id) {
            this.$emit('handleEdit', id);
        },

        handleDelete(id) {
            this.$emit('handleDelete', id);
        },
    },
};
</script>
